body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Login Form */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.logo-text {
  font-size: 70px;
  font-weight: bold;
}

.small-logo-text {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.heading-text{
  font-size: 30px;
  font-weight: bold;
}

.link-text {
  font-weight: bold;
  color: black;
  text-decoration: none;
  padding-left: 20px;
}

.img-pad {
  padding-left: 20px;
  padding-top: 20px;
}

.gry {
  background-color: #222222;
}

.nandosfont {
  font-family: 'Nandos';
}


@font-face {
  font-family: 'Nandos';
  src: local('Nandos'), url(./fonts/Nandos-Regular.ttf) format('truetype');
}